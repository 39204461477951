<template>
    <div id="main">
        <div class="login">
            <div class="logo">
                <el-image
                style="width: 270px; height: 70px"
                :src="logo"
                :fit="'contain'">
                </el-image>
            </div>
            <div class="form">
                <el-form class="formdata" :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
                    <el-form-item label="账号">
                        <el-input v-model="formLabelAlign.account" placeholder="请输入您的账号"></el-input>
                    </el-form-item>
                    <el-form-item label="密码">
                        <el-input v-model="formLabelAlign.password" placeholder="请输入您的密码" type="password"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <div class="btn">
                            <el-button @click="tologin"> welcome login</el-button>
                        </div>
                    </el-form-item>
                </el-form>  
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    // props:["logintype"],
    data() {
        return {
            logo:this.$logo,
            labelPosition: 'top',
            formLabelAlign: {
            account: '',
            password: '',
            },
            fullscreenLoading: false,
            msg:0
        }
    },
    mounted() {
    },
    methods: {
    //     openFullScreen1() {
    //     this.fullscreenLoading = true;
    //     setTimeout(() => {
    //       this.fullscreenLoading = false;
    //     }, 2000);
    //   },
        tologin(){
            if(this.formLabelAlign.account.length==0){
                this.$message.error('宝贝~账号没写~')
                return
            }else if(this.formLabelAlign.password.length==0){
                this.$message.error('宝贝~密码没写~')
                return
            }else{
                axios({
                    method:'post',
                    url: this.$host+this.$url.login,
                    data: {
                        account:this.formLabelAlign.account,
                        password:this.formLabelAlign.password
                    }   
                // }).then((res)=>{
                //     if(res.data == 'error'){
                //         this.$message.error('账号或密码错误,请检查是否开启大小写')
                //     }else{
                //         this.msg = 200
                //         localStorage.setItem('token',res.data)
                //         return this.msg
                //     }
                // }).then((msg)=>{
                //     if(msg == 200){
                //         this.$router.replace({path:'/'})
                //         this.$notify({
                //             title: '登录成功',
                //             message: '欢迎回来~',
                //             type: 'success'
                //             });
                //     }
                }).then((res)=>{
                    // console.log(res.data)
                    // 将登录返回的token，存入缓存中
                    if(res.data == 'error'){
                        this.$message.error('账号或密码错误,请检查是否开启大小写')
                        // this.formLabelAlign.account=''
                        this.formLabelAlign.password=''
                    }else{
                            localStorage.setItem('token',res.data)
                            this.$router.replace({path:'/'})
                            this.$notify({
                            title: '登录成功',
                            message: '欢迎回来~',
                            type: 'success'
                            });
                    }
                }).catch((err)=>{
                        console.log('err.response:'+err.response)
                        this.$notify({
                            title:'warning',
                            message:'服务器繁忙,请稍后重试',
                            type:'warning'
                        })
                })
            }
        },
        // 验证是否有token，如果有不让进
    },
}
</script>
<style scoped>
    #main{
        width: 100%;
        height: 100vh;
        background:url(../assets/img/1bg.png) no-repeat center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content:center;
    }
    .login{
        width: 542px;
        height: 392px;
        /* border: 1px solid #000; */
        margin-bottom: 20px;
    }
    .login .logo{
        width: 100%;
        height: 20%;
        /* border: 1px solid black; */
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .login .form{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .login .form .formdata{
        width: 63%;
    }
    .login .form .btn{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    button {
    padding: 1.3em 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #23c483;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
  }
  
  button:hover {
    background-color: #23c483;
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    color: #fff;
    transform: translateY(-7px);
  }
  
  button:active {
    transform: translateY(-1px);
  }

</style>